<template>
    <div class="cs-hero-block cs-block cs-no-padding" :class="styleClass">
        <div class="cs-block-base">
            <div class="background">
                <div class="content">
                    <div class="container">
                        <h1 v-if="data.title" class="cs-title">
                            <ColorSplit :data="data.title" />
                        </h1>
                        <h3 v-if="data.subTitle" class="cs-sub-title">{{data.subTitle}}</h3>
                        <span v-if="data.text" class="cs-text" v-html="data.text"></span>
                        <div v-if="data.buttons && ((typeof data.buttons == 'object' && Object.keys(data.buttons).length) || (Array.isArray(data.buttons) && data.buttons.length))">
                            <Buttons :data="data.buttons" :cs_style="data.style" />    
                        </div>
                        <div class="spacer"></div>
                    </div>
                </div>
            </div>
            <div class="image">
                <div class="container">
                    <CSMedia :data="data.image" :support="['image','video']" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';

    export default {
        name: "HeroSection",
        components: {
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
            Buttons: () => import("../components/CSUtilsButtons.vue"),
            CSMedia: () => import("../components/CSMedia.vue")
        },
        props: {
            data: {
                type: Object,
            default: () => {},
            },
        },
        data() {
            return {};
        },
        computed: computed('HeroSection')
    };
</script>

<style lang="scss" scoped>
    @import "../styles/main.scss";

    $block_space_xl: 150px;
    $block_space_lg: 50px;
    $block_space_md: 35px;
    $block_space_sm: 25px;
    $block_space: 15px;

    @mixin block-pt {
        padding-top: $block_space_xl;

        @media (max-width: 1199.98px) {
            padding-top: $block_space_lg;
        }

        @media (max-width: 991.98px) {
            padding-top: $block_space_md;
        }

        @media (max-width: 767.98px) {
            padding-top: $block_space_sm;
        }
    }

    @mixin block-pb {
        padding-bottom: $block_space_xl;

        @media (max-width: 1199.98px) {
            padding-bottom: $block_space_lg;
        }

        @media (max-width: 991.98px) {
            padding-bottom: $block_space_md;
        }

        @media (max-width: 767.98px) {
            padding-bottom: $block_space_sm;
        }
    }

    .cs-block.cs-hero-block{

        .background{ 
            @include cs-block-color("../assets/background-1-blue.svg");
        }

        ::v-deep span.cs-media {
            width: 100%;
            img,video {
                width: 100%;
            }
        }

        .cs-text{
            font-size: 1rem;
        }

        &.cs-style- {
            &color {
                .cs-sub-title{
                    color:$sub_title_color;
                }
                *, ::v-deep *{
                    color:white;
                }
                a,
                ::v-deep .cs-button {
                    color: white;
                    padding: 10px 15px;
                    border-radius: 30rem;
                    text-decoration: none;
                    text-align:center;

                    background: rgb(0,169,131);
                    background: linear-gradient(70deg, rgba(0,169,131,1) 0%, rgba(43,215,132,1) 100%);

                    &:hover {
                        background: rgb(7,199,211);
                        background: linear-gradient(70deg, rgba(7,199,211,1) 0%, rgba(0,255,255,1) 100%);
                    }
                }
            }

            &light {

                .background{ 
                    @include cs-block-light-image("../assets/background-1-grey.svg");
                }


                .cs-sub-title{
                    color: $sub_title_color_light;
                }
            }

            &dark {
                @include cs-block-dark;

                .cs-title{
                    color:white !important;
                }
                .cs-text{
                    color:white !important;
                }
                .cs-sub-title{
                    color: $sub_title_color_dark;
                }
            }
        }
        .cs-block-base{
            @include block-pb;

            .image{ 
                max-width: 800px;
                margin: auto;
                margin-top: -300px;

                ::v-deep {
                    img,
                    video {
                        width: 100%;   
                        height: auto;
                        object-fit: cover;
                        object-position: center 
                    }
                }
            }
            .content {
                max-width: 800px;
                margin: auto;
                @include block-pt;

                h1.cs-title {
                    font-size: 3.5rem;
                    font-weight: 900;
                    text-align: center;
                    margin-bottom: 3rem;
                    @media (max-width: 567px) {    
                        font-size: 2.5rem !important;
                    }
                }
                h3.cs-sub-title {
                    font-size: 1.5rem;
                    font-weight: 400;
                    text-align: center;
                    margin-bottom: 2rem;
                }
                span.cs-text{
                    text-align:center;
                    font-weight: 300;
                    letter-spacing: 0.3px;
                    line-height: 1.7rem;
                    margin-bottom: 4rem;
                    display: block;
                }
                .spacer {
                    height: 300px;
                }
            }


            .buttons {
                display: flex;
                margin-top: 20px;
                justify-content: center;
                margin-bottom: 4rem;
            }
            .button-group{
                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;                
                    margin: 15px;


                    a,
                    ::v-deep .cs-button {
                        color: white;
                        padding: 10px 15px;
                        border-radius: 30rem;
                        text-decoration: none;
                        text-align:center;

                        background: rgb(0,169,131);
                        background: linear-gradient(70deg, rgba(0,169,131,1) 0%, rgba(43,215,132,1) 100%);

                        &:hover {
                            background: rgb(49,223,140);
                            background: linear-gradient(90deg, rgba(49,223,140,1) 0%, rgba(49,223,140,1) 100%);
                        }
                    }
                }
                &:nth-child(1) .button a,
                &:nth-child(1) .button a ::v-deep .cs-button {
                    background: rgb(254,178,46);
                    background: linear-gradient(70deg, rgba(254,178,46,1) 0%, rgba(255,227,194,1) 100%);

                    &:hover {
                        background: rgb(255,202,123);
                        background: radial-gradient(circle, rgba(255,202,123,1) 0%, rgba(255,202,123,1) 100%);
                    }
                }

                &.cs-simple-button {
                    display: inline-block;
                }
                &.cs-advanced-button {

                    display: flex;
                    align-items: center;

                    .button {
                        width:30%;
                        padding:5px;
                        a,
                        ::v-deep .cs-button {
                            width:calc(100% - 10px);
                        }
                    }

                    .additional-info {
                        width: 70%;
                        padding: 15px 0;

                        span {
                            font-weight: 300;
                            letter-spacing: 0.3px;
                            font-size: 14px;
                        }

                        h5 {
                            font-size: 15px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            h3 {
                font-size: 20px;
                color:#d7dee5;
                font-weight: 400;
                padding: 10px 0;
            }
            &>span{
                font-weight: 300;
                letter-spacing: 0.3px;
                line-height: 1.7rem;
            }
        }


        .cs-col>div{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

        }

    }


</style>